import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { ArrowDown, ArrowForward } from "../components/Misc/SVG"

const ListingTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const frame = data.site.siteMetadata?.frame

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        slug={data.markdownRemark.fields.slug}
      />

      <section className="listing-body">
        <article
          className="listing-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
          </header>
          
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />

          <a
            id="listing-apply"
            href={'mailto:' + post.frontmatter.applyAddress + '?subject=' + post.frontmatter.applySubject}
          >
            <div className="copy">
              <h2>{frame.applyLabel}</h2>
              <p>{post.frontmatter.apply}</p>
            </div>
            <ArrowForward />
          </a>
        </article>

        <aside className="listing-summary">
          {post.frontmatter.salary && (
            <div>
              <p>{frame.salaryLabel}</p>
              <p><strong>{post.frontmatter.salary}</strong></p>
            </div>
          )}
          {post.frontmatter.type && (
            <div>
              <p>{frame.typeLabel}</p>
              <p><strong>{post.frontmatter.type}</strong></p>
            </div>
          )}
          <a
            href="#listing-apply"
            className="apply-anchor"
          >
            <p>{frame.applyLabel}</p>
            <ArrowDown />
          </a>
        </aside>
      </section>

    </Layout>
  )
}

export default ListingTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
        frame {
          salaryLabel
          typeLabel
          applyLabel
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        description
        salary
        type
        apply
        applyAddress
        applySubject
      }
    }
  }
`